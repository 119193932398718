/* header and navbar */
header {
  background-color: #EFEFEF;
}

.navbar {
  margin-bottom: 0;
  min-height: 70px;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  padding-top: 10px;
  max-width: 160px;
}

@media screen and (max-width: 1000px) {
  .navbar-brand img {
    max-width: 160px;
  }
  .navbar {
    min-height: 70px;
  }
}

@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width: 140px;
  }
  .navbar {
    min-height: 70px;
  }
}

.navbar-nav > li > a {
  padding-top: 25px;
  color: #C1272C;
  text-transform: uppercase !important;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.navbar-toggle {
  background-color: #C1272C;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px "Open Sans", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #C1272C;
}

a:hover, a:active {
  color: #C1272C;
}

h1, h2, h3, h4, h5, h6 {
  color: #C1272C;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #C1272C;
  border: 1px solid #C1272C;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

main {
  padding: 30px 0 0;
}

main h3 {
  font-weight: 700;
}

main h3.contact {
  margin: 0 auto 30px;
}

/* footer */
footer {
  background-color: #f7f7f6;
  padding: 30px 0;
  color: #222;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: auto;
}

footer .container {
  max-width: 85%;
}

footer a {
  display: none;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #C1272C;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #FFF !important;
  padding: 20px 15px 20px;
  border-bottom: 1px solid #ddd;
}

section.page-title-wrap h1.page-title {
  text-align: left;
  max-width: 1170px;
  font-size: 28px;
  line-height: 35px;
  color: #C1272C;
  font-weight: 700;
  margin: 0 auto;
}

/*home-intro is only only needed when included the signup-message partial */
section#home-intro {
  text-align: center;
  background-color: #EFEFEF;
  padding: 40px 0 60px;
}

section#home-intro img {
  margin: 40px 0 30px;
}

section#home-intro h3 {
  margin-bottom: 15px;
  padding: 0 10px;
  font-weight: 700;
  color: #401e07;
  font-size: 3.3rem;
}

section#home-intro p {
  max-width: 420px;
  font-size: 14px;
  padding: 10px 30px 20px;
  text-align: center;
  margin: auto;
}

section#home-intro a.call-to-action {
  background-color: #401e07;
  border: 1px solid #401e07;
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  padding: 10px 25px;
  border-radius: 3px;
  display: inline-block;
}

/* home features*/
.features .col-sm-4 {
  text-align: center;
  margin: auto;
}

.features .col-sm-4 i {
  color: #401e07;
  padding: 30px;
  text-align: center;
  margin: auto;
}

.features .col-sm-4 h3 {
  font-weight: 700;
}

/* site specific before_footer*/
.before-footer {
  border-top: 1px solid #dddddd;
  background-color: #EFEFEF;
  color: #222222;
  padding: 20px 0 30px;
  font-size: 12px;
  text-align: center;
  margin: 40px auto 0;
}

.before-footer span {
  color: #222;
}

.before-footer a {
  color: #222222;
  font-weight: 400;
  font-size: 13px;
  display: block;
  padding: 20px 0;
}
